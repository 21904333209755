<template>
  <div id="specaialpartice">
    <div class="top-title">
      <div>{{ name }}</div>
    </div>
    <div class="partice-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        :show-header="false"
        @row-click="choicetype"
      >
        <el-table-column prop="date" label="用户" width="1100">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.imageUrl" alt="" />

              <p>{{ scope.row.name }}</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column>
          <i class="el-icon-arrow-right"></i>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
    head: {
    title: "排行榜 - 西知题库",
  },
  data() {
    return {
      name: "",
      tableData: [
        {
          name: "单选题",
          num: 1,
          imageUrl: require("../../../assets/image/danxuanti.png"),
        },
        {
          name: "多选题",
          num: 2,
          imageUrl: require("@/assets/image/duoxuanti.png"),
        },
        {
          name: "判断题",
          num: 3,
          imageUrl: require("@/assets/image/panduanti.png"),
        },
        {
          name: "问答题",
          num: 4,
          imageUrl: require("@/assets/image/wendati.png"),
        },
        {
          name: "多元填空",
          num: 6,
          imageUrl: require("@/assets/image/duoyuantiankong.png"),
        },
        {
          name: "阅读理解",
          num: 9,
          imageUrl: require("@/assets/image/yueduti.png"),
        },
        {
          name: "听力题",
          num: 10,
          imageUrl: require("@/assets/image/tingliti.png"),
        },
      ],
    };
  },
  created() {
    this.name = this.$route.query.name;
    // let obj = {
    //   page: 1,
    //   limit: 99999,
    // };
    // this.$api.classes(obj).then((res) => {
    //   this.tableData=res.data.lists.data
    // });
  },
  methods: {
    choicetype(row) {
      console.log(row);
      let _this = this;
      _this.$router.push({
        path: "/Index/Pratice",
        query: {
          val: "专项练习",
          num: row.num,
          id: _this.$route.query.id,
          name: _this.name,
        },
      });
      //       this.$router.push('/Index/PersonalCenter/QuestionType')
    },
  },
};
</script>

<style lang="less" scoped>
#specaialpartice {
  padding-top: 50px;
  width: 1180px;
  margin: auto;
  min-height: 835px;
  .top-title {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .partice-list {
    /deep/.el-table {
      .cell {
        padding-left: 20px;
      }
    }
    /deep/.el-table td {
      padding: 20px 0;
    }
    table {
      .cell {
        font-size: 12px;

        font-weight: normal;
        > div {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;

            margin-right: 10px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>